<!--
 * @Author: your name
 * @Date: 2021-06-26 20:27:07
 * @LastEditTime: 2021-07-15 15:00:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \nice-store-new\src\views\components\content1.vue
-->
<template>
  <div class="content">
    <div class="inner_content">
      <div class="business_serve">
        <p>{{ $t("home.draw_business_service") }}</p>
        <div>BUSINESS</div>
        <div>&SERVICES</div>
      </div>
      <div class="serve_content">
        <ul class="serve_items">
          <li
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
            @click="changeTab(item)"
            v-for="(item, index) in tabList"
            :key="index"
          >
            <img :src="item.url" alt="" />
            <p>{{ item.name }}</p>
          </li>
        </ul>
      </div>
      <div class="about_notice">
        <div class="nav_list">
          <ul>
            <li
              @mouseover="tabNotice(1)"
              :class="tabIndex === 1 ? 'index_active' : ''"
            >
              {{ $t("home.dynamic") }}
            </li>
            <li
              @mouseover="tabNotice(2)"
              :class="tabIndex === 2 ? 'index_active' : ''"
            >
              {{ $t("home.draw_about") }}
            </li>
          </ul>
        </div>
        <div class="corr_item">
          <div class="notice_state" v-if="tabIndex === 1">
            <div @click.stop="goDetil($event)">
              <vue-seamless-scroll
                :data="goodchains_xinwenzhongxiaong"
                :class-option="classOption"
                class="table-content"
              >
                <ul>
                  <li
                    v-for="(item, index) in goodchains_xinwenzhongxiaong"
                    :key="index"
                    :data-id="item.id"
                  >
                    <a href="javascript:;" :data-id="item.id"
                      >{{ item.title }}...</a
                    ><span :data-id="item.id"
                      >[ {{ item.createTime | createformat() }} ]</span
                    >
                  </li>
                </ul>
              </vue-seamless-scroll>
            </div>
          </div>
          <div class="notice_state" v-else>
            <h1>{{ $t("home.micang_group") }}</h1>
            <div class="notice_intro">
              <p>{{ $t("home.company_info") }}</p>
              <!-- <a href=""></a> -->

              <div>
                <router-link :to="{ path: '/aboutRiceStroe' }"
                  >{{ $t("home.detailed_content") }}>></router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { goodchainsHomebankuai4 } from "@/api/request";
import { getWebsiteDynamic } from "@/api/websiteDynamic";
import moment from "moment";

export default {
  name: "content01",
  data() {
    return {
      isShowMorePic: false,
      isPlatTplePc: true,
      contentlist: [],
      titleObj: {},
      allLogo: "",
      tabIndex: 1,
      activeIndex: 1,
      tabList: [
        {
          name: this.$t("home.popcorn"),
          url: require("../../assets/img_index/server_bmh1.png"),
          tabId: 2,
        },
        {
          name: this.$t("home.garment"),
          url: require("../../assets/img_index/2.png"),
          tabId: 3,
        },
        {
          name: this.$t("home.industrial_products"),
          url: require("../../assets/img_index/3.png"),
          tabId: 1,
        },
        {
          name: this.$t("home.overseas_storage"),
          url: require("../../assets/img_index/4.png"),
          tabId: 4,
        },
      ],
      goodchains_xinwenzhongxiaong: [],
    };
  },
  computed: {
    locale() {
      return localStorage.getItem("language") || "zh";
    },
    classOption() {
      return {
        singleHeight: 0,
        waitTime: 2000,
        step: 0.3,
        hoverStop: true,
      };
    },
  },
  filters: {
    createformat(val) {
      return moment(val).format("YYYY-MM-DD");
    },
  },
  mounted() {
    const data = {
      sort: "sort,desc",
    };
    getWebsiteDynamic(data).then((res) => {
      const { content } = res;
      sessionStorage.setItem("newList", JSON.stringify(content));
      switch (this.locale) {
        case "en":
          this.goodchains_xinwenzhongxiaong = content.map((item) => {
            // if (item.infoEn) {
            //   const infoEn = JSON.parse(item.infoEn) || {};
            //   infoEn["id"] = item.id;
            //   return infoEn;
            // } else {
              const info = item;
              return info;
            // }
          });
          break;
        case "zh":
          this.goodchains_xinwenzhongxiaong = content;
          break;
        default:
          this.goodchains_xinwenzhongxiaong = content;
          break;
      }
    });
  },
  methods: {
    tabNotice(type) {
      this.tabIndex = type;
    },
    changeTab(item) {
      this.activeIndex = item.tabId;
      let query = {
        tabId: item.tabId,
      };
      this.$router.push({ path: "/businessServe", query });
    },
    goDetil(e) {
      const { id } = e.target.dataset;
      const list = this.goodchains_xinwenzhongxiaong;
      if (list && list.length) {
        //隐式转换
        const item = list.find((item) => item.id == id);
        this.$router.push({
          path: "/newCenterDetil",
          query: {
            id: item.id,
          },
        });
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./commonStyle.scss";
.content {
  background: #fff;
}
.activeColor {
  background: #f0f2f5;
}
.table-content {
  height: 150px;
  font-size: 14px;
  overflow: hidden;
}
// .notice_intro{
//   height: 100px;
//   overflow-y: auto;
// }
</style>