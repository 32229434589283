<!--
 * @Author: your name
 * @Date: 2021-06-26 20:27:07
 * @LastEditTime: 2021-07-09 16:42:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \nice-store-new\src\views\components\swiper.vue
-->
<template>
  <div class="swiper-box">
    <el-carousel :interval="5000" arrow="always">
      <div class="swiper_content" id="swiper_content">
        <el-carousel-item v-for="(item, index) in newCenterList" :key="index">
          <img
            @click="goNewcenterDetail(item)"
            :src="item.photoUrl"
            width="100%"
            height="100%"
          />
        </el-carousel-item>
      </div>
    </el-carousel>
  </div>
</template>
<script>
import { getWebsitePhoto } from "@/api/websitePhoto";

export default {
  name: "swiper",
  data() {
    return {
      //   imageList: [
      //     {
      //       url: require("../../assets/images/banner_swiper2.jpg"),
      //       name: "1",
      //       position: "技术架构师"
      //     },
      //     {
      //       url: require("../../assets/images/banner_swiper1.jpg"),
      //       name: "2",
      //       position: "技术架构师"
      //     },
      //     {
      //       url: require("../../assets/images/首页大图3.jpg"),
      //       name: "3",
      //       position: "技术架构师"
      //     },
      //     {
      //       url: require("../../assets/images/banner_swiper4.jpg"),
      //       name: "4",
      //       position: "技术架构师"
      //     }
      //   ],
      isPlatTplePc: true,
      newCenterList: [],
      publicPath: process.env.VUE_APP_BASE_API,
    };
  },
  created() {
    if (
      /Mobile|Android|webOS|iPhone|iPad|Phone/i.test(window.navigator.userAgent)
    ) {
      this.isPlatTplePc = false;
    } else {
      this.isPlatTplePc = true;
    }

    getWebsitePhoto().then((res) => {
      const { content } = res;
      const locale = localStorage.getItem("language" || "zh");
      switch (locale) {
        case "en":
          this.newCenterList = content.map((item) => {
            if (item.infoEn) {
              return JSON.parse(item.infoEn) || {};
            } else {
              return item;
            }
          });
          break;
        case "zh":
          this.newCenterList = content;
          return;
        default:
          this.newCenterList = content;
          return;
      }
      // this.newCenterList = this.obj;
    });
  },
  mounted() {
    this.$nextTick(() => {
      let mulPx = 1980 / 638;
      let bannerHeight = document.documentElement.clientWidth / mulPx;
      bannerHeight = bannerHeight + "px";
      let swpierHeight = document.getElementById("swiper_content");
      swpierHeight.style.height = bannerHeight;
    });
  },
  methods: {
    goNewcenterDetail(item) {
      if (!item.url) return;
      window.location.href = item.url;
      // this.$router.push({
      //   path: "/newCenterDetil",
      //   query: {
      //     linkPage: "/index",
      //     item: item.neirong,
      //     newCenterTitle: item.biaoti,
      //     creatTime: item.create_date
      //   }
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__container {
  height: 100%;
}
.swiper-box {
  height: 100%;
  .swiper-wrapper {
    height: 100%;
  }
  ::v-deep.el-row {
    height: 100%;
  }
  ::v-deep.el-col {
    height: 100%;
  }
  ::v-deep.el-carousel--horizontal {
    height: 100%;
  }
  ::v-deep.el-carousel__container {
    height: 100%;
  }
  ::v-deep.el-carousel__arrow {
    i {
      color: #fff;
    }
  }
  .swiper_content {
    width: 100%;
    // height: 798px;
  }
  img {
    object-fit: cover;
  }
}
</style>