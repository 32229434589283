<!--
 * @Author: your name
 * @Date: 2021-06-26 20:27:07
 * @LastEditTime: 2021-07-02 09:38:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \nice-store-new\src\views\components\Homepage.vue
-->
<template>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <my-swiper />
      <content01 />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
import "animate.css/animate.min.css";
import MySwiper from "./swiper";
import content01 from "./content1";

export default {
  name: "Homepage",
  components: {
    MySwiper,
    content01
  },
  data() {
    return {
      lang: "chinese",
      pageIndex: 0,
      stepIndex: 0,
      headerHeight: 80
    };
  },
  beforeCreate() {},
  created() {
    if (
      /Mobile|Android|webOS|iPhone|iPad|Phone/i.test(window.navigator.userAgent)
    ) {
      this.headerHeight = 44;
    } else {
      this.headerHeight = 80;
    }
  },
  mounted() {
    this.initSwiper();
    let { goodchains_homebankuai3 } = window;
    console.log("goodchains_homebankuai3", goodchains_homebankuai3);
  },
  methods: {
    initSwiper() {
      this.$nextTick(() => {
        new Swiper(".swiper-container", {
          direction: "vertical",
          mousewheel: true, //开启鼠标
          speed: 800, //滚动速度
          height: window.innerHeight, // 高度设置，占满设备高度
          mousewheel: {
            releaseOnEdges: true //PC端释放滑动
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true
          }
          // breakpoints: {
          //   768: {
          //     slidesPerView: 2,
          //     touchReleaseOnEdges: true //移动端释放滑动
          //   }
          // }
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
element::-webkit-scrollbar {
  display: "none";
}
.swiper-container {
  width: 100%;
  .swiper-pagination {
    position: absolute;
    top: 400px;
  }
}
.swiper-slide {
  min-height: 600px;
}
::v-deep.swiper-pagination-bullet-active {
  color: #d90000;
  background: #d90000;
}
</style>
