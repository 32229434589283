/**
 * 首页
 *
 * @author hezhendong 20210316
 */
<template>
  <div class="first_index">
    <el-row :gutter="24">
      <el-col :span="24">
        <Homepage />
      </el-col>
    </el-row>
  </div>

</template>

<script>
import Homepage from "../components/Homepage";
export default {
  name: "Home",
  components: {
    Homepage
  }
};
</script>
<style lang="scss" scoped>
.home {
  // width: 100%;
  // margin: 0 auto;
}
</style>
